(function () {
  initBackTop();
  initScrollTop();
  initMobileNavScroll();
  initNavScroll();

  $(window).on('resize', function () {
    initMobileNavScroll();
  });
})();

function initBackTop() {
  $(window).scroll(function () {
    var load = $(window).scrollTop();
    if (load < 400) {
      $('#back-top').fadeOut(500);
    } else {
      $('#back-top').fadeIn(500);
    }

    if (window.innerWidth < 768) {
      if (
        $(window).scrollTop() + $(window).height() <
        $(document).height() - $('.disclaimer').outerHeight()
      ) {
        $('#back-top').css('bottom', '');
      } else {
        $('#back-top').css('bottom', '135px');
      }
    }
  });
}

function initScrollTop() {
  $('#back-top').on('click', function () {
    $.fn.fullpage.moveTo('landing');

    // $('body').animate(
    //   {
    //     scrollTop: 0,
    //   },
    //   500
    // );
    // console.log('first');
    // return false;
  });
}

function initMobileNavScroll() {
  if ($(window).innerWidth() < 768) {
    $('#nav-home').click(function () {
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $('#section-landing').offset().top,
        },
        500
      );
    });

    $('#nav-location').click(function () {
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $('#section-location').offset().top,
        },
        500
      );
    });
    
    $('#nav-home-m').click(function () {
      document.body.classList.remove("menu-opened");
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $('#section-landing').offset().top,
        },
        500
      );
    });

    $('#nav-location-m').click(function () {
      document.body.classList.remove("menu-opened");
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $('#section-location').offset().top,
        },
        500
      );
    });

    $('#nav-register').click(function () {
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $('#section-register').offset().top,
        },
        500
      );
    });

    $('.mobile-contact').click(function () {
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $('#section-contact').offset().top,
        },
        500
      );
    });

    $('#list-suites').click(function () {
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $('#suites').offset().top - 150,
        },
        500
      );
    });

    $('#list-residence').click(function () {
      console.log('clicked');
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $('#residence').offset().top - 150,
        },
        500
      );
    });

    $('#list-retail').click(function () {
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $('#retail').offset().top - 150,
        },
        500
      );
    });
  }
}

function initNavScroll() {
  $('#list-suites, #tab-suites').click(function () {
    $.fn.fullpage.moveTo('suites');
  });

  $('#list-residence, #tab-residences').click(function () {
    $.fn.fullpage.moveTo('residence');
  });

  $('#list-retail, #tab-retail').click(function () {
    $.fn.fullpage.moveTo('retail');
  });

  if ($(window).innerWidth() > 767) {
    $('#nav-home').click(function () {
      $.fn.fullpage.moveTo('landing');
    });

    $('#nav-location').click(function () {
      $.fn.fullpage.moveTo('location');
    });

    $('#nav-register').click(function () {
      $.fn.fullpage.moveTo('register');
    });

    $('#nav-contact').click(function () {
      $.fn.fullpage.moveTo('contact');
    });

    $('#list-suites').click(function () {
      $.fn.fullpage.moveTo('suites');
    });

    $('#list-residence').click(function () {
      $.fn.fullpage.moveTo('residence');
    });

    $('#list-retail').click(function () {
      $.fn.fullpage.moveTo('retail');
    });
  }

  $(
    '#nav-home, #nav-location, #nav-register, #nav-contact, #list-suites, #list-residence, #list-retail'
  ).click(function () {
    $('#back-top').fadeIn(500);
    $('#back-top').on('click', function () {
      // $.fn.fullpage.moveTo('landing');
      // $('#back-top').fadeOut(500);
    });
  });
}
