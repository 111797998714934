//Register Form
const API = {
  register: 'https://1pavilionlead.fusionqb.com/api/v1/leads/regleadproc',
  agent: 'https://1pavilionlead.fusionqb.com/api/v1/leads/createexternalagent',
};

const NEXCRM_PAYLOAD = {
  Projects: '9',
  Source: '35',
};

const formValidation = () => {
  const form = document.querySelector('form#register-form');
  const submitButton = form.querySelector('.submit-button');
  const preloader = document.querySelector('.preloader');

  let salutation = document.querySelector('select[name=salutation]');
  let country = document.querySelector('select[name=ffs_country]');
  let mobile = document.querySelector('input[name=ffscontactno]');

  country.addEventListener('change', (e) => {
    const countrycode =
      e.target.options[e.target.selectedIndex].dataset['countrycode'];

    mobile.value = countrycode;
  });

  if (form) {
    form.addEventListener('submit', (e) => {
      e.preventDefault();
    });

    submitButton && submitButton.addEventListener('click', async (e) => {
      e.preventDefault();

      let email = document.querySelector('input[name=ffsemail]');
      let interests = document.querySelectorAll('input.residence-check');
      let interest = document.querySelector('input[name=residence_interest]');
      let name = document.querySelector('input[name=ffsname]');
      let agentCheck = document.querySelector('input[name=ffsagent]');
      let agentNum = document.querySelector('input[name=ffsagennumber]');
      let pdpa = document.querySelector('input[name=ffspdpa');

      const errMessages = document.querySelectorAll('.err-message');

      errMessages.forEach((err) => {
        err.classList.remove('show');
      });

      let err = false;

      // Obtain these values again
      country = document.querySelector('select[name=ffs_country]');
      mobile = document.querySelector('input[name=ffscontactno]');

      // Reset value
      interest.value = '';
      interests.forEach((i) => {
        if (i.checked == 1) {
          interest.value += `${i.value}; `;
        }
      });

      const requiredFields = form.querySelectorAll('[required]');
      console.log(requiredFields);
      requiredFields.forEach((field) => {
        if (field.value == '') {
          err = true;
          console.log(field.name);
          const err_message = form.querySelector(
            `.err-required[data-required=${field.name}]`
          );

          err_message.classList.add('show');
        }
      });

      let emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
      let mobilenoReg = /^[0-9\+]{7,20}$/;

      if (email.value.trim() != '' && !email.value.trim().match(emailReg)) {
        const err_message = form.querySelector(
          `.err-invalid[data-required=${email.name}]`
        );
        err = true;
        err_message.classList.add('show');
      }

      if (
        mobile.value.trim() != '' &&
        !mobile.value.trim().match(mobilenoReg)
      ) {
        const err_message = form.querySelector(
          `.err-invalid[data-required=${mobile.name}]`
        );
        err = true;
        err_message.classList.add('show');
      }

      if (agentCheck.checked === true && agentNum.value == '') {
        const err_message = form.querySelector(
          `.err-required[data-required=${agentNum.name}]`
        );
        err = true;
        err_message.classList.add('show');
      }

      if (pdpa.checked === false) {
        const err_message = form.querySelector(
          `.err-required[data-required=${pdpa.name}]`
        );
        err = true;
        err_message.classList.add('show');
      }

      if (!err) {
        let formData = new FormData();
        preloader.classList.add('show');

        const ffsData = {
          ffs_country: country.value,
          salutation: salutation.value,
          ffsname: name.value,
          ffscontactno: mobile.value,
          ffsemail: email.value,
          residence_interest: interest.value,
        };

        console.log(ffsData);
        if (agentCheck.checked && agentNum.value != '') {
          ffsData.ffsagent = agentNum.value;
        }

        for (const key in ffsData) {
          formData.append(key, ffsData[key]);
        }

        try {
          const response = await fetch('/API/ajax-contact.php', {
            method: 'POST',
            body: formData,
            timeout: 60000,
          });

          if (!response.ok) {
            const errorMessage = response.statusText || 'Failed to fetch data';
            throw new Error(errorMessage);
          }

          console.log(response);

          const responseData = await response.json();
          if (responseData?.status === 'success') {
            let url = API.register;
            let payload = {
              ...NEXCRM_PAYLOAD,
              Country: country.value,
              Salutation: salutation.value,
              Name: name.value,
              ContactNo: mobile.value,
              Email: email.value,
              SourceType: [],
              SourceTypeOther: '',
              interest: interest.value,
            };

            console.log(payload);
            console.log(responseData.status);

            if (agentCheck.checked && agentNum.value != '') {
              payload.RenNum = agentNum.value;
              url = API.agent;
            }
            console.log(url);
            console.log(payload);

            try {
              let crm_res = await fetch(url, {
                method: 'POST',
                body: JSON.stringify(payload),
                headers: {
                  'Content-Type': 'application/json',
                },
              });

              if (!crm_res.ok)
                throw new Error(`HTTP error! Status: ${crm_res.status}`);

              // Check if the response is JSON
              if (
                crm_res.headers.get('content-type').includes('application/json')
              ) {
                let text = await crm_res.json();
                console.log(text);

                res = text;
              } else {
                let text = await crm_res.text();
                console.log(text);
                res = text;
              }
            } catch (error) {
              console.log(error.message);
              res = error.message;
            }

            document.querySelector('.form-body').remove();
            document.querySelector('.form-thank-you').classList.add('d-flex');
            document
              .querySelector('.form-thank-you')
              .classList.remove('d-none');
          } else {
            console.log(responseData);
          }
        } catch (error) {
          console.error('Caught an error:', error);
        }

        preloader.classList.remove('show');
      }
    });
  }
};

if(document.querySelector('form#register-form'))  formValidation();
