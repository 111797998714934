import 'bootstrap/dist/js/bootstrap';

import './components/smoothScroll';
import './components/fullpage';
import './components/scrolloverflow.min';
// import "./components/particles";

import './pages/scroll';
import './pages/home';
import './pages/validation';

import Zoomist from 'zoomist';

window.addEventListener('DOMContentLoaded', () => {
  const zoomContainers = document.querySelectorAll('.zoomist-container');

  if (zoomContainers.length > 0) {
    zoomContainers.forEach((zoom) => {
      const zoomist = new Zoomist(zoom, {
        // Optional parameters
        maxScale: 4,
        bounds: true,
        wheelable: false,
        // if you need slider
        // slider: true,
        // if you need zoomer
        zoomer: {
          inEl: 'button.zoomist-zoomer-in',
          outEl: 'button.zoomist-zoomer-out',
          resetEl: 'button.zoomist-zoomer-reset',
        },
      });
    });
  }
});
