import AOS from "aos";

/**
 * hamburger trigger function for mobile
 */
function toggleMenu() {
  const BODY = document.body || document.documentElement;
  const menuToggle = document.querySelector("[menu-toggle]");

  menuToggle && menuToggle.addEventListener("click", () => {
    BODY.classList.toggle("menu-opened");
  });
}

(function () {
  initCheckbox();
  initSlidePanel();
  // initNavScroll();
  // initMobileNavScroll();
  setAsideDimension();
  // setCarouselNavHeight();
  initTransition();
  initFullPageJS();
  // initBackTop();
  initRegisterScroll();
  initIconBar();
  // initLiving();
  initPaginationLabel();
  initLocationLegend();
  initPopupModal();

  // init menu trigger
  toggleMenu();

  AOS.init({
    once: true,
    mirror: true,
    duration: 1000,
  });

  $("#modalPrivacy").on("show.bs.modal", function () {
    $.fn.fullpage.setAllowScrolling(false);
  });

  $("#modalPrivacy").on("hidden.bs.modal", function () {
    $.fn.fullpage.setAllowScrolling(true);
  });

  $(window).on("resize", function () {
    setAsideDimension();
    // setCarouselNavHeight();
    // initFullPageJS();
    // initNavScroll();
    // initMobileNavScroll();
    // initBackTop();
    initRegisterScroll();
    initIconBar();
  });

  $("#carouselResidence").bind("slide.bs.carousel", function (e) {
    let other = e.from != 0 ? true : false;
    //change to second caption
    if (e.to == 0) {
      $("#carouselResidence .carousel_caption")
        .fadeOut()
        .promise()
        .done(() => {
          $("#carouselResidence .carousel_caption").eq(0).fadeIn();
          // $('#carouselResidence .overlay-border').addClass('overlay-flex');
          if (window.innerWidth < 992 && window.innerWidth > 767) {
            // $('#carouselResidence .backBtn').css('bottom', '');
            $("#carouselResidence .button-wrapper").css("padding-top", "0");
          } else if (window.innerWidth < 768) {
            $("#carouselResidence .backBtn").css("bottom", "");
          }
        });
    } else if (!other && e.to != 0) {
      $("#carouselResidence .carousel_caption")
        .fadeOut()
        .promise()
        .done(() => {
          $("#carouselResidence .carousel_caption").eq(e.to).fadeIn();
          // $('#carouselResidence .overlay-border').removeClass('overlay-flex');
          if (window.innerWidth < 992 && window.innerWidth > 767) {
            // $('#carouselResidence .backBtn').css('bottom', '70%');
            $("#carouselResidence .button-wrapper").css("padding-top", "1rem");
          } else if (window.innerWidth < 768) {
            // $('#carouselResidence .backBtn').css('bottom', '18%');
            $("#carouselResidence .button-wrapper").css("padding-top", "1rem");
          }
        });
    } else if (other) {
      $("#carouselResidence .carousel_caption")
        .fadeOut()
        .promise()
        .done(() => {
          $("#carouselResidence .carousel_caption").eq(e.to).fadeIn();
          // $('#carouselResidence .overlay-border').removeClass('overlay-flex');
          if (window.innerWidth < 992 && window.innerWidth > 767) {
            // $('#carouselResidence .backBtn').css('bottom', '70%');
            $("#carouselResidence .button-wrapper").css("padding-top", "1rem");
          } else if (window.innerWidth < 768) {
            // $('#carouselResidence .backBtn').css('bottom', '18%');
            $("#carouselResidence .button-wrapper").css("padding-top", "1rem");
          }
        });
    }
  });
})();

function initLocationLegend() {
  const trainLines = document.querySelectorAll(".train-line");
  const routes = document.querySelectorAll(".route-container");

  trainLines.forEach((ele) => {
    ele.addEventListener("click", () => {
      const lineDataset = ele.dataset.line;

      routes.forEach((el) => {
        el.classList.remove("active");
        const routeDataset = el.dataset.content;

        if (lineDataset === routeDataset) {
          el.classList.add("active");
        }
      });
    });
  });
  // $('#Legend .rail-icon').on('click',function() {
  //   let container;
  //   var data = $(this).attr('data-content');

  //   container = $('#section-location').find(`.route-container[data-content="${data}"]`);

  //   $('.route-container').each(function() {
  //     $(this).removeClass('active');
  //   });

  //   $(container).addClass('active');
  // });

  // $('#Legend .legend-icon').on('click', function() {

  //   $('#section-location svg').each(function() {
  //     var activeIcons = $(this).find('.active-icon');

  //     $(activeIcons).attr('class', 'icons');
  //   });

  //   let legendID = $(this).attr('id');

  //   var icons = $('#section-location').find('.' + legendID);

  //   $(icons).find('.icons').each(function () {

  //     $(this).attr('class', 'icons active-icon');
  //   });
  // });
}

function initPaginationLabel() {
  $("#pagination li a")
    .mouseover(function () {
      $(this).parent().children(".label").addClass("active-label");
    })
    .mouseleave(function () {
      $(this).parent().children(".label").removeClass("active-label");
    });
}

function initLiving() {
  let data;
  let parentEle;
  let el;
  $(".info-image").mouseover(function () {
    data = $(this).data("id");
    data = "#" + data;

    parentEle = $(this).parent().parent().attr("id");
    el = "#" + parentEle + "-2";

    $(el + ".description-header h5").html($(data).html());
  });

  $(".info-image").on("click", function () {
    parentEle = "#" + parentEle + " .information .info-image";
    $(parentEle).not($(this)).removeClass("selected");
    let element = $(this).find(".hidden-copy");

    $(this).addClass("selected");

    $(el + " .description-info").html($(element).html());
    $(el + " .description-info").show("normal");
    $(el + " #click-info")
      .text($(el + " #click-info").data("close"))
      .fadeIn();

    if ($(el + " #click-info").text() == "Close") {
      $(el + " #click-info").addClass("close-info");
    }
  });

  $("#living-2 #click-info, #business-2 #click-info").on("click", function () {
    let parent = $(this).parent().parent().attr("id");
    parent = "#" + parent;
    if ($(this).text() == "Close") {
      $(parent + " .description-info").hide("normal");
      $(parent + " #click-info").removeClass("close-info");
      $(parent + " #click-info")
        .text($("#click-info").data("click"))
        .fadeIn();
      $(parent + " .description-info p").text("");
    }
  });
}

function initIconBar() {
  if (window.innerWidth < 768) {
    $(document).find("#icon-bar").addClass("list-group-horizontal");
    $(document).find("#icon-bar").addClass("list-group");
  } else {
    $(document).find("#icon-bar").removeClass("list-group-horizontal");
    $(document).find("#icon-bar").removeClass("list-group");
  }
}

function initRegisterScroll() {
  $(".register-btn").on("click", function () {
    // let btnParent = $(this).parent().parent().parent().parent().parent().parent();
    // let mobileParent = $(this).parent().parent().parent().parent().parent().parent().parent();
    if ($(window).innerWidth() > 767) {
      // $(btnParent).removeClass('is-open');

      // if (window.innerWidth > 767) {
      //   $.fn.fullpage.setAllowScrolling(true);
      // }
      $.fn.fullpage.moveTo("register");
      // $('.icon-wrapper, .copyright-wrapper').fadeIn(300);
    } else {
      // $([document.documentElement, document.body]).animate({
      //   scrollTop: $("#section-register").offset().top
      // }, 500);

      // var hideNav = function() {
      //   // $(mobileParent).removeClass('is-open');
      // };
      var scrollMobile = function () {
        var r = $.Deferred(function () {
          $([document.documentElement, document.body]).animate(
            {
              scrollTop: $("#section-register").offset().top,
            },
            500
          );

          // $('body').css('overflow', 'auto');

          setTimeout(function () {
            r.resolve();
          }, 500);
        });

        return r;
      };
      // scrollMobile().done(hideNav);
      scrollMobile();
    }
  });

  const anchor = window.location.hash;
  if (anchor && anchor != "" && anchor == "#section-registration") {
    // check section
    if ($(window).innerWidth() > 767) {
      setTimeout(() => {
        $.fn.fullpage.moveTo("register");
      }, 500);
    } else {
      var scrollMobile = function () {
        var r = $.Deferred(function () {
          $([document.documentElement, document.body]).animate(
            {
              scrollTop: $("#section-register").offset().top,
            },
            500
          );

          // $('body').css('overflow', 'auto');

          setTimeout(function () {
            r.resolve();
          }, 500);
        });

        return r;
      };
      scrollMobile();
    }
  }
}

function initFullPageJS() {
  // if ($(window).innerWidth() > 767) {
  // $('#back-top').on('click', function() {
  //   $.fn.fullpage.moveTo('navigation');
  // });
  // }
  $("#fullpage").fullpage({
    licenseKey: "050C6F79-FFCF4F8B-B4373866-BBF4621A",
    fitToSection: true,
    responsiveHeight: 580,
    responsiveWidth: 992,
    scrollOverflow: true,
    normalScrollElements: "#modalPrivacy, .location-map",
    menu: "#pagination",
    // scrollBar: true,
    // anchors: ['nav', 'landing', 'tab', 'residence', 'suites', 'living', 'business', 'location', 'register', 'contact', 'footer'],
    // normalScrollElements: '.banner-img-2',
    onLeave: function (origin, destination, direction) {
      $(".section [data-aos]").each(function () {
        $(this).removeClass("aos-animate");
      });

      if (origin.index == 0 && direction == "down") {
        $("#back-top").fadeIn(500);
        $("#back-top").on("click", function () {
          $.fn.fullpage.moveTo("body");
          $("#back-top").fadeOut(500);
        });
      } else if (origin.index == 2 && direction == "up") {
        $("#back-top").fadeOut(500);
      } else if (origin.index == 10 && direction == "down") {
        $(".copyright-wrapper").fadeOut(300);
        if (window.innerWidth > 767) {
          $(".icon-wrapper").css("bottom", "25%");
        }
      } else if (origin.index == 11 && direction == "up") {
        $(".copyright-wrapper").fadeIn(300);
        if (window.innerWidth > 767) {
          $(".icon-wrapper").css("bottom", "");
        }
      }
    },
    onSlideLeave: function () {
      $(".slide [data-aos]").each(function () {
        $(this).removeClass("aos-animate");
      });
    },
    afterSlideLoad: function () {
      $(".slide.active [data-aos]").each(function () {
        $(this).addClass("aos-animate");
      });
    },
    afterLoad: function () {
      $(".section.active [data-aos]").each(function () {
        $(this).addClass("aos-animate");
      });
    },
    afterResponsive: function (isResponsive) {
      if (isResponsive == true) {
        $(".section").addClass("fp-auto-height-responsive");
      } else {
        $(".section").removeClass("fp-auto-height-responsive");
      }
    },
  });
}
// }

function initTransition() {
  var openClass = "is-open";
  var minHeight = "minHeight";
  let parentBanner;
  let btnBanner;

  $(
    "#residence-btn .inner-btn, #suites-btn .inner-btn, #retail-btn .inner-btn"
  ).on("click", function () {
    // if ($(window).innerWidth() < 768) {
    // $('body').css('overflow', 'hidden');
    // }
    parentBanner = $(this).parent().attr("id");
    parentBanner = "#" + parentBanner.slice(0, -4);

    if (window.innerWidth < 992) {
      let mainComp = $(parentBanner).parent();
      $(mainComp).addClass(minHeight);
    }

    btnBanner = $(this);
    $(btnBanner).fadeOut();
    $(parentBanner).fadeOut();

    let banner = $(this).attr("id");
    // let bannerId = '#' + banner.slice(0,-7);
    banner = "#" + banner + "-2";

    $(banner).addClass(openClass);
    // $('.icon-wrapper, .copyright-wrapper').fadeOut(300);
    // if($('#back-top, #mobile-back-top').is(':visible')) {
    //   $('#back-top, #mobile-back-top').fadeOut('300');
    // }

    // $.fn.fullpage.setAllowScrolling(false);
  });

  $("#mdiv, .backBtn").on("click", function () {
    // $(parentBanner).fadeIn();
    // $(btnBanner).fadeIn();
    // console.log(mainComp);

    $(this)
      .parents("div")
      .each(function () {
        if ($(this).hasClass("overlay")) {
          $(this).removeClass(openClass);
        }

        if (window.innerWidth < 992) {
          if ($(this).hasClass("fp-tableCell")) {
            // $(this).removeClass(minHeight);
            var childElem = $(this).children();

            $(childElem).each(function () {
              if ($(this).hasClass("minHeight")) {
                $(this).removeClass(minHeight);
              }
            });
          }
        }

        if ($(this).children().hasClass("banner-wrapper")) {
          var mainBanner = $(this).children();

          $(mainBanner).children().fadeIn();
          $(mainBanner).find(".inner-btn").fadeIn();
        }
      });

    // $('.overlay').removeClass(openClass);
    // $('body').css('overflow', 'auto');
    // $('.backBtn').css('bottom', '');
    // $('.icon-wrapper').fadeIn(300);
    // if (window.innerWidth > 767) {
    //   $('.copyright-wrapper').fadeIn(300);
    //   $('#back-top').fadeIn(500);
    //   // $.fn.fullpage.setAllowScrolling(true);
    //   $('#back-top').on('click', function() {
    //     $.fn.fullpage.moveTo('navigation');
    //     $('#back-top').fadeOut(300);
    //   });
    // } else {
    //   $('#mobile-back-top').fadeIn(500);
    // }
  });
}

function setAsideDimension() {
  var panelHeader = $("aside .rotate");
  var sidePanelHeight = $("aside").innerHeight();
  var sidePanelWidth = $("aside").innerWidth();

  panelHeader.css("height", sidePanelWidth);
  panelHeader.css("width", sidePanelHeight);
}

function initCheckbox() {
  const checkmark = $(".checkbox-wrapper .checkmark");
  const input = $(".checkbox-wrapper input");

  checkmark.each(function (index, ele) {
    const eleId = $(ele).attr("id");

    $(ele).on("click", function () {
      input.each(function (i, element) {
        const inputId = $(element).attr("id");

        if (eleId == inputId) {
          $(element).trigger("click");
          $(element).toggle(this.checked);
        }
      });
    });
  });
}

function initSlidePanel() {
  var body = $(".panel-wrapper");
  var panels = $(".panel-swap");
  var dom_classname = "is-anchored";
  var nav = $(".nav-item").find("a");

  panels.on("click", function () {
    body.toggleClass(dom_classname);
    let active = $(this).attr("id");

    nav.each(function () {
      if ($(this).attr("id") == active) {
        $(this).removeClass("active");
      } else {
        $(this).addClass("active");
      }
    });
  });

  nav.on("click", function () {
    body.toggleClass(dom_classname);
    let active_ = $(this).attr("id");

    nav.removeClass("active");

    if ($(this).attr("id") == active_) {
      $(this).addClass("active");
    }
  });
}

function initPopupModal() {
  $("#popupModal").modal("show");
}
